import { Button } from 'components/Button';
import { captureException } from 'utils/sentry';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetStaticProps } from 'next';
import { InfinitePosts } from 'components/InfinitePosts';
import { MaxWidth } from 'components/MaxWidth';
import { Page } from 'components/Page';
import { RichText } from 'components/RichText';
import { Section } from 'components/Section';
import { UpvotingRequiredModal } from 'components/UpvotingRequiredModal';
import { usePostsQuery } from 'generated/graphql';
import { useRestoreScrollPosition } from 'utils/useRestoreScrollPosition';
import { useState } from 'react';
import { useUser } from 'components/UserProvider';

const POSTS_PER_PAGE = 30;
const OFFSET_KEY = '15TWENTY_HOME_OFFSET';

export const getStaticProps: GetStaticProps = async () => {
  try {
    const queryClient = new QueryClient();
    await Promise.all(
      Array.from({ length: 3 }).map((_, index) => {
        const variables = { offset: index * POSTS_PER_PAGE, first: POSTS_PER_PAGE };
        return queryClient.prefetchQuery(usePostsQuery.getKey(variables), usePostsQuery.fetcher(variables));
      })
    );
    return { props: { dehydratedState: dehydrate(queryClient) }, revalidate: 600 };
  } catch (error) {
    captureException(error);
    return { props: {} };
  }
};

const HomePage = () => {
  useRestoreScrollPosition();
  const { user } = useUser();
  const [showModal, setShowModal] = useState(false);

  return (
    <Page>
      <MaxWidth>
        <Section>
          <RichText>
            <h1>
              A community of <span>hiphop fanatics</span>, discovering the best of the industry.
            </h1>
            {!user && (
              <>
                <p>
                  Discover and discuss the best in hiphop music. Every day, we gather the best new- and existing tracks,
                  albums and videos in hiphop.
                </p>
                <Button href="/register">Create an account</Button>
              </>
            )}
          </RichText>
        </Section>

        <InfinitePosts offsetKey={OFFSET_KEY} postsPerPage={POSTS_PER_PAGE} setShowModal={setShowModal} />
      </MaxWidth>

      {showModal && <UpvotingRequiredModal onClose={() => setShowModal(false)} />}
    </Page>
  );
};

export default HomePage;
